"use client"

import { HeroUIProvider } from "@heroui/react";

export default function ThemeNextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
      <HeroUIProvider>
          {children}
      </HeroUIProvider>
  )
} 